<template>
  <article class="accordion-block" :class="{'active': show}">
    <header v-on:click="toggle">
      <p>{{ content.title }}</p>
      <i class="accordion-block__details" :class="{'active' : show}"></i>
    </header>
    <transition name="accordion">
      <div class="accordion-block__body" v-show="show">
        <div class="accordion-block__inner">
            <div class="title_table"> <p>Субъект РФ</p></div>
            <div class="title_table"> <p>ФИО председателя ОНК</p></div>
            <div class="title_table"> <p>Контакты ОНК</p> </div>
            <div class="title_table"> <p>Кол-во членов ОНК, утвержденное советом ОП РФ</p></div>
            <div class="title_table"> <p>Дата истечения полномочий</p></div>
           <div class="title_table"> <p>№ состава</p></div>
        </div>
        <div class="accordion-block__inner" v-for="(item, i) of getSortedItems" :key="'region'+i">
            <p class="content_table"> {{item.address}} </p>
            <p class="content_table">  {{item.chairman}} </p>
            <a :href="`mailto:${item.email}`" class="content_table">  {{item.email}} </a>
            <p class="content_table"> {{item.memberCount}} </p>
            <p class="content_table"> {{ (item.first_meeting_date!==null) ? item.first_meeting_date.split('T')[0] : ''}} </p>
           <p class="content_table">  {{item.structure}} </p>
        </div>
      </div>
    </transition>
  </article>

</template>

<script>
export default {
  name: 'AccordionInfomation',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    toggle: function () {
      this.show = !this.show
    }
  },
  computed: {
    getSortedItems () {
      return 'data' in this.content ? [...this.content.data].sort((a, b) => {
        return a.address.localeCompare(b.address)
      }) : []
    }
  }
}
</script>

<style scoped lang="scss">
  .accordion-block {
    padding: 1.25rem 1.875rem;
    border: .06rem solid #E5E8EE;
    border-radius: 0.5rem;
    //
    margin-bottom: 1.875rem;
    font-size: .875rem;
    line-height: 1.57;

    &.active {
      box-shadow: 0 .94rem 1.56rem rgba(189, 205, 221, 0.35);
    }

    header {
      color: #1F3245;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & p {
        margin-right: 5rem;
      }

      & i {
        border: .1875rem solid #9D9D9D;
        border-width: 0 .1875rem .1875rem 0;
        border-radius: .0625rem;
        display: inline-block;
        padding: .1875rem;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-bottom: .0625rem;
      }

      .active {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
    }

    &__inner {
        display: flex;
        width: 100%;
        align-items: flex-start;
        padding: 1.38rem 0;
        border-top: .06rem solid #D5D6D9;
        //
        &:nth-child(1){
            border-top: 0;
            padding: 2rem 0 1rem 0;
            align-items: flex-end;
        }
        &:last-child{
            padding-bottom: 0;
        }
        .title_table p{
            font-size: .81rem;
            line-height: 1.25rem;
            color: #5E686C;
            position: relative;
            width: fit-content;
        }
        > .title_table:nth-child(1){ width: 19.44%; }
        > .title_table:nth-child(2){ width: 18.4%; }
        > .title_table:nth-child(3){ width: 18.35%; }
        > .title_table:nth-child(4){ width: 17.09%; padding-right: 3.7rem;}
        > .title_table:nth-child(5){ width: 19.12%; }
        > .title_table:nth-child(6){ width: 7.6%; }
        //
        .content_table{
            font-size: 0.75rem;
            line-height: 1.125rem;
            color: #5E686C;
        }
        > .content_table:nth-child(1){
            width: 19.44%;
            color: #1F3245;
            font-weight: 600;
        }
        > .content_table:nth-child(2){
            width: 18.4%;
            color: #1F3245;
            padding-right: 3.18rem;
        }
        > .content_table:nth-child(3){
            width: 18.35%;
            color: #246CB7;
        }
        > .content_table:nth-child(4){ width: 17.09%; }
        > .content_table:nth-child(5){ width: 19.12%; }
        > .content_table:nth-child(6){ width: 7.6%; }
    }

  }
.title_table{
    display: flex;
    align-items: center;
}
.icon_table{
    margin-left: 0.75rem;
    width: .47rem;
    height: .47rem;
    background: url('../../assets/icons/filterTable.svg') center center no-repeat;
    background-size: .47rem;
    padding: .3rem;
    cursor: pointer;
}
@media screen and (max-width: 768px){
  .accordion-block{
    &__inner{
      width: 96.78%;
    }
  }
}
@media screen and (max-width: 420px){
  .accordion-block{
    padding: 1.25rem 1.38rem;
    &__body{
      overflow-x: scroll;
    }
    &__inner{
      width: 333%;
      .title_table, .content_table{
        &:nth-child(1) {
          width: 16.44%;
        }
        &:nth-child(2) {
          width: 17.4%;
        }
        &:nth-child(4) {
          width: 24.09%;
        }
        &:nth-child(5) {
          width: 16.12%;
        }
      }
    }
    header{
      p{
        margin-right: .125rem;
      }
    }
  }
}
</style>
