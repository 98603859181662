<template>
    <div class="block_main">
        <bread-crumb :navigationArray="navigationLink"/>
        <section class="white-container">
            <h5>Общественные наблюдательные комиссии</h5>
            <tricolor-line />
            <div class="tabs">
                <p v-for="(tab, index) in tabs.list" :class="{'active' : isActiveTab === index}" :key="`tab${index}`" :id="`tab${index}`" @click="setTab(index)">{{ tab }}</p>
            </div>
            <component :is="tabs.pages[isActiveTab]" :content="content[isActiveTab]" />
        </section>
        <section class="white-container section_block">
            <h5>Контактная информация отдела по работе с ОНК</h5>
            <tricolor-line />
            <p class="white-container__text">За более подробной информацией по вопросам выдвижения кандидатур в
              состав общественных наблюдательных комиссий субъектов Российской Федерации можно обратиться по телефонам:</p>
            <div class="contact_block">
                <div>
                  <!-- <p>{{contact.contact_1[0]}}</p> -->
                  <a :href="`tel:${contact.contact_1[1]}`">{{contact.contact_1[1]}}</a>
                </div>
                <div>
                  <!-- <p>{{contact.contact_2[0]}}</p> -->
                  <a :href="`tel:${contact.contact_2[1]}`">{{contact.contact_2[1]}}</a>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
//
import News from '@/components/monitoringCommissions/News'
import InformationEducated from '@/components/monitoringCommissions/InformationEducated'
import InformChanges from '@/components/monitoringCommissions/InformChanges'
import Documents from '@/components/monitoringCommissions/Documents'
import Partners from '@/components/monitoringCommissions/Partners'

export default {
  name: 'PublicMonitoringCommissions',
  data () {
    return {
      isActiveTab: Number(this.$route.query.tab) || 0,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Общественные наблюдательные комиссии'
        }
      ],
      tabs: {
        list: [
          'Новости',
          'Информация об образованных ОНК в субъектах РФ',
          'Информируем об изменениях в составах ОНК субъектов РФ',
          'Документы',
          'Партнеры'
        ],
        pages: [
          'news-public',
          'information-educated',
          'inform-changes',
          'documents-public',
          'partners-public'
        ]
      },
      contact: [
        // {
        //   name: 'Крайнова Ольга Алексеевна',
        //   telephon: '8 (495) 221-83-63, доб. 2152'
        // },
        // {
        //   name: 'Копылова Евгения Владимировна',
        //   telephon: '8 (495) 221-83-63, доб. 3181'
        // }
      ],
      content: [],
      systemTagList: ['/public_monitoring_commissions']
    }
  },
  methods: {
    setTab (index) {
      this.isActiveTab = index
    }
    // contentApi (array) {
    //   const prepeareArray = []
    //   array.map((item, i) => {
    //     const test = []
    //     this.$store.getters.getDocuments.map(obj => {
    //       if (obj.tagList.indexOf(item) !== -1) {
    //         test.push(obj)
    //       }
    //     })
    //     console.log(test)
    //     prepeareArray.push(test)
    //   })
    //   this.content = [...this.content, ...prepeareArray]
    // }
  },
  watch: {
    '$store.getters.getContactInformationOnk' () {
      this.contact = this.$store.getters.getContactInformationOnk
    }
    // '$store.getters.getNews' (value) {
    // this.arrayRes = value
    // let array = []
    // console.log(value)
    // value.map(item => {
    //   item.tagList.map(data => {
    //     array.push(data)
    //   })
    // })
    // array = uniq(array)
    // this.contentApi(array)

    // const counterDocumentsDevice = []
    // array.map(item => {
    //   counterDocumentsDevice.push('documents-device')
    // })
    // this.tabs.pages = [...this.tabs.pages, ...counterDocumentsDevice]
    // this.tabs.list = [...this.tabs.list, ...array]
    // }
  },
  mounted () {
    // const formData = new FormData()
    // formData.append('systemTagList', JSON.stringify(this.systemTagList))
    // this.$store.dispatch('setDocuments', formData)
    this.$store.dispatch('setContactInformationOnk')
    this.$store.dispatch('getNewsCommissions', 'public_monitoring_commissions')
    this.$store.commit('setFilterSystemTagList', 'public_monitoring_commissions')
    document.title = 'Общественные наблюдательные комиссии'
  },
  components: {
    'bread-crumb': BreadCrumb,
    'tricolor-line': TricolorLine,
    'news-public': News,
    'information-educated': InformationEducated,
    'inform-changes': InformChanges,
    'documents-public': Documents,
    'partners-public': Partners
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: auto;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;

    &__text{
        font-size: .88rem;
        line-height: 1.38rem;
        color: #1F3245;
        width: 42.81rem;
        margin: 2rem 0 2.38rem 0;
    }
}
.section_block{
    margin-top: 3.125rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 1.81rem;
}
.tabs{
    display: flex;
    border-radius: .125rem;
    border-bottom: .06rem solid #D5D6D9;
    margin: 2rem 0;

    p{
        font-size: .75rem;
        line-height: 1.125rem;
        color: #003E78;
        opacity: 0.6;
        margin-right: 1.25rem;
        padding-bottom: 1.375rem;
        cursor: pointer;
        transition: font-size .2s;
    }

    .active {
        font-weight: 600;
        font-size: .813rem;
        line-height: 1.25rem;
        color: #246CB7;
        border-bottom: .125rem solid #246CB7;
        padding-bottom: 1.25rem;
        margin-bottom: -0.07rem;
        margin-top: -0.15rem;
        transition: font-size .2s;
        opacity: 1;
    }
}
.contact_block{
    display: flex;

    div{
        margin-right: 8.38rem;
        p{
            font-size: .75rem;
            line-height: 1.125rem;
            color: rgba(23, 23, 23, 0.8);
            opacity: 0.8;
            margin-bottom: .56rem;
        }
        a{
            font-size: 1rem;
            line-height: 1.25rem;
            color: #246CB7;
        }
    }
}
@media screen and (max-width: 768px){
  .block_main{
    // overflow-x: hidden;
  }
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;

    &__text {
      width: 100%;
    }

  }
  .section_block{
    margin-top: 3.125rem;
  }
  .tabs{
        overflow-x: auto;
        flex-wrap: nowrap;
        margin: 1.38rem -0.76rem 1.38rem 0;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        p{
            flex: 0 0 auto;
            padding-bottom: 1.05rem;
            margin-right: 1rem;
        }
        > .active{
            margin-bottom: 0;
            padding-bottom: 1rem;
        }
    }
    #tab4{ padding-right: .76rem;}
    .tabs::-webkit-scrollbar { width: 0; height: 0;}
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .contact_block {
      div {
        margin: 0 0 1.5rem 0;
      }
      flex-direction: column;
    }
}
</style>
