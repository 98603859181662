<template>
    <div>
        <p class="title_section">Информация об образованных ОНК в субъектах РФ</p>
        <accordion-infomation v-for="(item, i) of resArray" :key="i+'accord'" :content="item"/>
    </div>
</template>
<script>
import AccordionInfomation from '@/components/monitoringCommissions/AccordionInfomation'
export default {
  name: 'InformationEducated',
  components: {
    AccordionInfomation
  },
  data () {
    return {
      resArray: []
    }
  },
  async mounted () {
    let res = await fetch(`${this.$store.getters.getUrlApi}api/regional-public-сhamber`)
    res = await res.json()
    this.resArray = await res.data
  }
}
</script>
<style lang="scss" scoped>
.title_section{
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #1F3245;
  margin-bottom: 1.38rem;
}
.accordion-block:last-child{
  margin-bottom: 0;
}
</style>
