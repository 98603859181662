<template>
    <div>
        <p class="title_section">Партнеры</p>
        <div class="info-block">
            <div class="info-banner" v-for="item of $store.getters.getNkoPartners" :key="item.title+'block'">
                <span class="info-banner__type" :style="`background: url('${item.image}') center / cover`"></span>
                <a :href="item.link" class="info-banner__title" v-trim="2">{{ item.title }}</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Partners',
  mounted () {
    this.$store.dispatch('setNkoPartners')
  }
}
</script>
<style lang="scss" scoped>
.title_section{
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1F3245;
    margin-bottom: 1.38rem;
}
.info-block{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 1.375rem -1.06rem -2rem -1.06rem;
}
.info-banner {
  margin: 0 1.06rem 2rem 1.06rem;
  width: calc(33.333% - 2.125rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;

  &__type {
    width: 100%;
    height: 14.25rem;
    margin-bottom: 1.25rem;
    box-shadow: 0 .94rem 1.56rem rgba(189, 205, 221, 0.35);
    border-radius: .5rem;
    border: .06rem solid #E5E8EE;
  }
  &__title{
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #1F3245;
    width: 100%;
  }
}
@media screen and (max-width: 768px){
  .info-banner {
    width: calc(50% - 2.125rem);
  }
}
@media screen and (max-width: 420px){
  .info-block{
    margin: 1.375rem 0 -2rem 0;
  }
  .info-banner {
      width: 100%;
      margin: 0 0 3rem  0;

  }
}
</style>
