<template>
  <router-link tag="div" :to="{ path: `comment/${card.id}` }" v-if="!listView" class="news-quote">
    <p v-trim="1" class="news-quote__preview">{{ card.title }}</p>
    <div class="news-quote__name">
      <p class="news-quote__photo" :style="`background: url('${'/assets/temp/' + card.author.photo}') center / cover`"></p>
      <div>
        <p class="news-quote__name">{{ `${card.author.firstName} ${card.author.lastName}` }}</p>
        <p class="news-quote__data">{{ card.createDate }}</p>
      </div>
    </div>
    <blockquote class="news-quote__block">
      <div v-trim="3">
        <div class="news-comment__text" v-html="card.text"></div>
      </div>
    </blockquote>
  </router-link>

  <router-link tag="div" :to="{ path: `comment/${card.id}` }" v-else class="news-quote">
    <div class="news-quote__name list">
      <p class="news-quote__photo" :style="`background: url('${'/assets/temp/' + card.author.photo}') center / cover`"></p>
      <div>
        <p class="news-quote__name">{{ `${card.author.firstName} ${card.author.lastName}` }}</p>
        <p class="news-quote__data">{{ card.createDate }}</p>
      </div>
    </div>
    <p v-trim="1" class="news-quote__preview list">{{ card.title }}</p>
    <!-- <blockquote class="news-quote__block">
      <div v-trim="2">
        <div class="news-comment__text" v-html="card.text"></div>
      </div>
      <span>Перейти к коментарию</span>
    </blockquote> -->

    <div class="news-quote__block ckEditor-html desctop_block" v-html="fullText ? card.text : sliceText(card.text, 170)">
      <span v-if="sliceActive" @click="seeFullText">{{textMore}}</span>
    </div>
    <div class="news-quote__block ckEditor-html mobel_block" v-html="fullText ? card.text : sliceText(card.text, 70)">
      <span v-if="sliceActive" @click="seeFullText">{{textMore}}</span>
    </div>
  </router-link>

</template>

<script>
export default {
  name: 'CommentNewsCard',
  props: {
    card: {
      type: Object
    },
    listView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      textMore: 'Посмотреть весь текст',
      fullText: false,
      sliceActive: false
    }
  },
  methods: {
    seeFullText () {
      this.fullText = !this.fullText
      this.fullText === true ? this.textMore = 'Скрыть' : this.textMore = 'Посмотреть весь текст'
    },
    sliceText (text, value) {
      if (text.length > value) {
        this.sliceActive = true
        var str = text.slice(0, value)
        var a = str.split(' ')
        a.splice(a.length - 1, 1)
        return a.join(' ') + ' ... '
      } else return text
    }
  }
}
</script>

<style scoped lang="scss">
.news-quote {
  padding: 1.25rem 1.875rem;
  width: 26.56rem;
  height: 16.25rem;
  box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);

  border: 1px solid #EBF3FF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 2.5rem;

  &__preview {
    font-weight: 600;
    font-size: .875rem;
    line-height: 157%;
    color: rgba(23, 23, 23, 0.8);

    margin-bottom: 0.375rem;
  }

  &__photo {
    width: 3.75rem;
    height: 3.75rem;
  }

  &__name{
    display: flex;
    font-weight: bold;
    font-size: 1rem;
    line-height: 150%;
    color: #1F3245;

    margin-bottom: 0.35rem;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1.25rem;
    }
  }

  &__type {
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 123%;
    text-transform: uppercase;

    padding: .125rem .625rem;
    border-radius: 3px;
    width: max-content;

    color: #003E78;
    background: #DDEDFE;
  }

    &__data {
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 150%;
    color: rgba(23, 23, 23, 0.8);
  }

  &__block {
    position: relative;
    padding: 1.25rem 3rem;

    font-size: .875rem;

    background: #F1F4FA;
    border-radius: 8px;
  }
  &__block:before,
  &__block:after {
    position: absolute;
    color: #BFE2FF;
    font-size: 100px;
    font-family: Times, sans-serif;
    line-height: 100px;
  }
  &__block:before {
    content: '”';
    color: #003E78;
    font-size: 2.5rem;
    left: 1rem;
    top: -.7rem;
  }
  &__block:after {
    content: '”';
    color: #003E78;
    font-size: 2.5rem;
    right: 1rem;
    bottom: -2rem;
  }

  &__name.list {
    margin: 0.875rem 0;
  }

  &__preview.list {
    margin-bottom: 0.875rem;
  }
}
.mobel_block{
  display: none;
}
@media screen and (max-width: 420px){
  .desctop_block{
    display: none;
  }
  .mobel_block{
    display: block;
    max-height: 5.2rem;
    overflow: hidden;
  }
}
</style>
