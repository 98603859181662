<template>
  <div>
    <p class="name_section">
      <slot>Информируем об изменениях в составах ОНК субъектов РФ</slot>
    </p>
    <router-link class="info-banner" v-for="(content, i) of getCommissionUpdate" :key="i+'block'"
                 :to="`/termination-office?id=${content.id}`">
      <p class="info-banner__date">{{ dateArr(content.date) }}</p>
      <p class="info-banner__name" v-trim="1">{{ content.type }}</p>
      <p class="info-banner__text" v-trim="2">{{ content.shortText }}</p>
    </router-link>
    <pagination class="news__pagination"
                :per-page="perPage"
                :current="offset / perPage + 1"
                :total="$store.getters.getCommissionTotal"
                @page-changed="getPage"/>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'

export default {
  name: 'InformChanges',
  props: {
    data: {
      type: Array,
      default: function () {
        return [
          {
            id: 0,
            date: '01.01.2020',
            name: 'Прекращение полномочий',
            text: 'Из-за кардинально изменившихся условий реформирования отрасли по обращению с отходами и для того, чтобы через четыре года опять не пришлось переносить реформу, Секретарем ОП РФ Валерием Фадеевым принято решение ежемесячно проводить встречи в субъектах РФ с представителями власти, бизнеса, осуществляющего свою деятельнос  РФ с представителями власти, бизнеса, осуществляющего свою деятельнос ',
            link: '/termination-office'
          },
          {
            id: 0,
            date: '01.01.2020',
            name: 'Прекращение полномочий',
            text: 'Из-за кардинально изменившихся условий реформирования отрасли по обращению с отходами и для того, чтобы через четыре года опять не пришлось переносить реформу, Секретарем ОП РФ Валерием Фадеевым принято решение ежемесячно проводить встречи в субъектах РФ с представителями власти, бизнеса, осуществляющего свою деятельнос  РФ с представителями власти, бизнеса, осуществляющего свою деятельнос ',
            link: '/termination-office'
          },
          {
            id: 0,
            date: '01.01.2020',
            name: 'Прекращение полномочий',
            text: 'Из-за кардинально изменившихся условий реформирования отрасли по обращению с отходами и для того, чтобы через четыре года опять не пришлось переносить реформу, Секретарем ОП РФ Валерием Фадеевым принято решение ежемесячно проводить встречи в субъектах РФ с представителями власти, бизнеса, осуществляющего свою деятельнос  РФ с представителями власти, бизнеса, осуществляющего свою деятельнос ',
            link: '/termination-office'
          },
          {
            id: 0,
            date: '01.01.2020',
            name: 'Прекращение полномочий',
            text: 'Из-за кардинально изменившихся условий реформирования отрасли по обращению с отходами и для того, чтобы через четыре года опять не пришлось переносить реформу, Секретарем ОП РФ Валерием Фадеевым принято решение ежемесячно проводить встречи в субъектах РФ с представителями власти, бизнеса, осуществляющего свою деятельнос  РФ с представителями власти, бизнеса, осуществляющего свою деятельнос ',
            link: '/termination-office'
          },
          {
            id: 0,
            date: '01.01.2020',
            name: 'Прекращение полномочий',
            text: 'Из-за кардинально изменившихся условий реформирования отрасли по обращению с отходами и для того, чтобы через четыре года опять не пришлось переносить реформу, Секретарем ОП РФ Валерием Фадеевым принято решение ежемесячно проводить встречи в субъектах РФ с представителями власти, бизнеса, осуществляющего свою деятельнос  РФ с представителями власти, бизнеса, осуществляющего свою деятельнос ',
            link: '/termination-office'
          },
          {
            id: 0,
            date: '01.01.2020',
            name: 'Прекращение полномочий',
            text: 'Из-за кардинально изменившихся условий реформирования отрасли по обращению с отходами и для того, чтобы через четыре года опять не пришлось переносить реформу, Секретарем ОП РФ Валерием Фадеевым принято решение ежемесячно проводить встречи в субъектах РФ с представителями власти, бизнеса, осуществляющего свою деятельнос  РФ с представителями власти, бизнеса, осуществляющего свою деятельнос ',
            link: '/termination-office'
          }
        ]
      }
    }
  },
  data () {
    return {
      perPage: 10,
      offset: 0
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.$store.dispatch('setCommissionUpdate', {
        offset: this.offset,
        limit: this.perPage
      })
    },
    getPage (page) {
      this.offset = (page - 1) * this.perPage
      this.getData()
    },
    dateArr (date) {
      return (new Date(date).toLocaleString('ru', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }).slice(0, -3))
    }
  },
  computed: {
    getListView () {
      return this.$store.getters.getListView
    },
    getCommissionUpdate () {
      return this.$store.getters.getCommissionUpdate
    }
  },
  components: {
    Pagination
  }
}
</script>
<style lang="scss" scoped>
  .name_section {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1F3245;
    margin-bottom: 1.38rem;
  }

  .info-banner {
    margin-bottom: 1.88rem;
    padding: 1.25rem 3.31rem 1.25rem 1.875rem;
    width: 100%;

    box-shadow: 0px .94rem 1.56rem rgba(189, 205, 221, 0.35);
    border-radius: .5rem;
    border: .06rem solid #E5E8EE;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.875rem;

    &__type {
      width: 2.8125rem;
      height: 2.8125rem;
      margin-bottom: auto;
      // margin-bottom: 1.25rem;
    }

    &__date {
      font-size: .875rem;
      line-height: 1.37rem;
      color: #5E686C;
      opacity: 0.8;
      margin-bottom: .625rem;
    }

    &__name {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.38rem;
      color: #1F3245;
      margin-bottom: .312rem;
    }

    &__text {
      font-size: .875rem;
      line-height: 1.38rem;
      color: #171717;
      opacity: 0.8;
    }

  }

  .news__pagination {
    padding-top: .625rem
  }
</style>
