<template>
    <div>
       <tabs-header class="card_containet__tabs" :section="{ title: section.title, url_link: `${section.url_link}?tab=News`, url_name: section.url_name}" />
        <div class="info-banner">
            <component :is="card.rubric ? 'news-banner' : 'comment-news-card'" v-for="(card, key) in dataNews.slice(0,9)" :card="card"
            :key="`news${key}`" class="info-banner__card"/>
        </div>
    </div>
</template>
<script>
import TabsHeader from '@/components/indexPage/TabsHeader'
import NewsBanner from '@/components/news/NewsBanner'
import CommentNewsCard from '@/components/news/CommentNewsCard'
export default {
  name: 'News',
  components: {
    'tabs-header': TabsHeader,
    'news-banner': NewsBanner,
    'comment-news-card': CommentNewsCard
  },
  data () {
    return {
      section: {
        title: 'Новости',
        url_link: '/news',
        url_name: 'Посмотреть все'
      }
    }
  },
  computed: {
    dataNews () {
      return this.$store.getters.getNewsCommissions
    }
  }
}
</script>
<style lang="scss" scoped>
.info-banner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 1.375rem -1.06rem -1.87rem -1.06rem;

  &__card{
    margin: 0 1.06rem 1.87rem 1.06rem;
    width: calc(33.333% - 2.125rem);
  }
}

@media screen and (max-width: 768px){
  .info-banner {
    &__card{
      width: calc(50% - 2.125rem);
    }
  }
}
@media screen and (max-width: 420px){
  .info-banner {
    &__card{
      width: calc(100% - 2.125rem);
    }
  }
}
</style>
