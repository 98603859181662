<template>
    <div>
        <p class="title_section">Документы</p>
        <ul class="documents__type-list">
        <li v-for="(type, key) of documentsType" class="documents__type-item"
            :class="{'documents__active' : isActiveItem === key}"
            :key="`type${key}`"
            @click="ActiveItem(type, key)">
          {{ type }}
        </li>
        </ul>
      <h2 class="title-section" >{{ this.titleActive }}</h2>
        <div class="partner-info" v-for="(item, key) of arrayRes" :key="key+'doc'">
            <div class="img_block"></div>
            <div class="info_block">
                <p>{{item.name}}</p>
                <download-file :dataFile="item" class="download-file"/>
            </div>
        </div>
    </div>
</template>
<script>
import DownloadFile from '@/components/DownloadFile'
import uniq from '@/methods/uniq'
export default {
  name: 'Documents',
  data () {
    return {
      systemTagList: ['/onk_document'],
      arrayRes: [],
      arrayResAll: [],
      content: [
        {
          title: 'Федеральный закон от 10 июня 2008 г. №76-ФЗ «Об общественном контроле за обеспечением прав человека в местах принудительного содержания и о содействии лицам, находящимся в местах принудительного содержания»',
          url: '/',
          size: '256',
          type: 'PDF'
        },
        {
          title: 'Рекомендации по выдвижению кандидатов в члены общественных наблюдательных комиссий по общественному контролю за обеспечением прав человека в местах принудительного содержания и содействию лицам, находящимся в местах принудительного содержания',
          url: '/',
          size: '256',
          type: 'PDF'
        },
        {
          title: 'Кодекс этики',
          url: '/',
          size: '256',
          type: 'PDF'
        },
        {
          title: 'Образцы заполнения документов о выдвижении кандидатур в состав ОНК',
          url: '/',
          size: '256',
          type: 'PDF'
        },
        {
          title: 'Доклад Общественной палаты Российской Федерации, посвященный вопросам деятельности общественных наблюдательных комиссий в субъектах Российской Федерации',
          url: '/',
          size: '256',
          type: 'PDF'
        }
      ],
      isActiveItem: -1,
      documentsType: [],
      titleActive: 'Все'
    }
  },
  methods: {
    ActiveItem (name, i) {
      this.isActiveItem = i
      this.titleActive = name
      this.arrayRes = []
      this.arrayResAll.map(item => {
        if (item.tagList.includes(name)) {
          this.arrayRes.push(item)
        }
      })
    },
    dateArr (date) {
      return (new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3))
    },
    async setDocuments (year, title) {
      const formdata = new FormData()
      formdata.append('systemTagList', JSON.stringify(this.systemTagList))
      let res = await fetch(`${this.$store.getters.getUrlApi}api/document/list`, {
        method: 'POST',
        body: formdata
      })
      res = await res.json()
      this.arrayResAll = res.data.documents
      this.arrayRes = res.data.documents
      const arrayTags = []
      this.arrayResAll.map(item => {
        item.tagList.map(val => {
          arrayTags.push(val)
        })
      })
      this.documentsType = uniq(arrayTags)
    }
  },
  watch: {
    documentsType (value) {
      if (value.length) {
        this.ActiveItem(this.documentsType[0], 0)
      }
    }
  },
  mounted () {
    this.setDocuments()
  },
  components: {
    DownloadFile
  }
}
</script>
<style lang="scss" scoped>
.title_section{
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1F3245;
    margin-bottom: 1.38rem;
}

.title-section {
  font-weight: 600;
  font-size: 1rem;
  color: #1F3245;
  margin-bottom: 2rem;
}
.documents {
  &__type-list {
    margin-left: 1.375rem;
    margin-bottom: 3.125rem;
  }
  &__type-item {
    list-style: disc;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #246CB7;

    cursor: pointer;
  }
  &__filter {
    margin-bottom: 1.875rem;
    display: inline-flex;
  }

  &__filter-select {
    width: 7.5rem;
  }

  &__filter-link {
    padding-left: 1.375em;
    display: flex;
    align-items: center;

    font-size: .75rem;
    line-height: 1.125rem;
    color: #003E78;
    opacity: 0.6;

    &::after {
      content: '';
      margin-left: 0.75rem;
      width: 0.7rem;
      height: 0.7rem;
      background-image: url('../../assets/icons/icon_link.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__list {
    margin-bottom: 2.5rem;
  }
  &__active {
    font-weight: 600;
  }
}

.partner-info{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.88rem;
}
.partner-info:last-child{
    margin-bottom: 0;
}
.img_block{
    position: relative;
    width: 2.82rem;
    height: 2.82rem;
    border-radius: 50%;
    background: url('../../assets/icons/document_icon.svg') center center no-repeat;
    background-size: 1rem;
    background-color: #eaf1f8;
}
.info_block{
    margin-left: 2.5rem;
    width: calc(100% - 2.82rem - 2.5rem);
    display: flex;
    justify-content: space-between;
    p{
        font-weight: 600;
        font-size: .88rem;
        line-height: 1.38rem;
        color: #1F3245;
        width: 42.81rem;
    }
}
.download-file{
    padding: .7rem 0 0 3.125rem;
    border-left: #5a79a87d .06rem solid;
    height: 3.13rem;
}
@media screen and (max-width: 768px){
    .info_block{
        margin-left: 1.5rem;
        flex-direction: column;
        p{
            width: 100%;
        }
        .download-file{
            padding: .7rem 0 0 0;
            border-left: none;
            height: unset;
        }
    }
}
</style>
