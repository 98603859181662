<template>
  <router-link :to="{ path: `news/${card.id}` }" v-if="!listView" class="news-banner" :style="getNewsBannerStyle">
    <span class="news-banner__type" :class="{'news-banner__type1' : !card.preview_photo}">{{ card.rubric_model.title }}</span>
    <p v-trim="3" class="news-banner__title" :class="{'news-banner__title1' : !card.preview_photo}">{{ card.title }}</p>
    <p class="news-banner__polls" :class="{'news-banner__polls1' : !card.preview_photo}">{{ card.description }}</p>
    <p class="news-banner__data" :class="{'news-banner__data1' : !card.preview_photo}">{{ getFormattedDate(new Date(card.date_time)) }}</p>
  </router-link>
  <router-link :to="{ path: `news/${card.id}` }" v-else class="news-banner list">
    <p class="news-banner__type list">{{ card.rubric.title }}</p>
    <p class="news-banner__data list">{{ getFormattedDate(new Date(card.date_time)) }}</p>
    <p v-trim="1" class="news-banner__title list">{{ card.title }}</p>
  </router-link>
</template>
<script>
import formatDate from '@/mixins/formatDate'
export default {
  name: 'NewsBanner',
  mixins: [formatDate],
  props: {
    card: {
      type: Object
    },
    listView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getNewsBannerStyle () {
      return {
        background: this.card.preview_photo ? `linear-gradient(to right, rgba(0,0,0,.5), rgba(0,0,0,.5)), url('${this.card.preview_photo}') center / cover` : 'rgba(255, 255, 255)'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.news-banner {
  padding: 1.25rem 1.875rem;
  width: 26.56rem;
  height: 16.25rem;
  box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 2.5rem;

  &__title {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 133%;
    color: #FFF;

    margin-bottom: 0.375rem;
  }
  &__title1 {
    color: #1F3245;
  }

  &__polls {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 157%;
    color: #FFF;
  }
  &__polls1{
    color: rgba(23, 23, 23, 0.8);
    mix-blend-mode: normal
  }

  &__type {
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 123%;
    text-transform: uppercase;
    color: #003E78;

    padding: .125rem .625rem;
    margin-bottom: 1.25rem;
    background: #FFFFFF;
    border-radius: 3px;
  }

  &__type1 {
    color: #003E78;
    background: #DDEDFE;
  }

  &__data {
    font-size: 0.75rem;
    line-height: 150%;
    color: #FFF;

    margin-top: auto;
  }
  &__data1{
    color: rgba(23, 23, 23, 0.8);
  }

  &.list {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    height: unset;
  }
  &__type.list {
    color: #003E78;
    background: #DDEDFE;
  }
  &__data.list{
    font-size: 0.875rem;
    line-height: 157%;
    width: 70%;
    margin: 0 0 1.25rem;

    color: rgba(23, 23, 23, 0.8);
    margin-left: 1.25rem;
  }
  &__title.list {
    color: #1F3245;
    font-size: .875rem;
    width: 100%;
  }
}

@media screen and (max-width: 768px){
  .news-banner {
    height: unset;
    min-height: 12.125rem;
    margin: 0 .5rem 2.5rem;

    &__polls {
      display: none;
    }
    &__title {
      font-size: 0.875rem;
      line-height: 157%;
    }
    &__data.list {
      width: 50%;
    }
  }
  .news-banner.list {
    display: flex;
    height: auto;
    min-height: unset;
  }
}

@media screen and (max-width: 420px){
  .news-banner {

  }

}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .news-banner {
    &__polls, &__title {
      width: 100%;
    }
  }
}

</style>
